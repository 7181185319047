<template>
	<div id="app">
		<a-modal :visible="show" title="编辑任务" width="40%" @ok="doSubmit" @cancel="_close" centered>
			<a-form-model ref="form" :model="formData" :rules="formRule">
				<a-row :gutter="8">
					<a-col :span="14">
						<a-form-model-item label="BEAN" label-position="top" prop="beanName">
							<a-input v-model="formData.beanName" :disabled="formData.taskId != ''" placeholder="请输入Bean名称"></a-input>
						</a-form-model-item>
					</a-col>
					<a-col :span="10">
						<a-form-model-item label="方法名称" label-position="top" prop="methodName">
							<a-input v-model="formData.methodName" :disabled="formData.taskId != ''" placeholder="方法名称"></a-input>
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="8">
					<a-col :span="16">
						<a-form-model-item label="cron表达式" label-position="top" prop="cronExpression">
							<a-input v-model="formData.cronExpression" placeholder="0/10 * * * * ?"></a-input>
						</a-form-model-item>
					</a-col>
					<a-col :span="8">
						<a-form-model-item label="状态" label-position="top" prop="status">
							<a-radio-group button-style="solid" v-model="formData.status" :false-value="0" :true-value="1">
								<a-radio-button :value="1">正常</a-radio-button>
								<a-radio-button :value="0">暂停</a-radio-button>
							</a-radio-group>
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="8">
					<a-col :span="12">
						<a-form-model-item label="备注说明" label-position="top" prop="remark">
							<a-input v-model="formData.remark" type="textarea" placeholder="请输入备注说明"></a-input>
						</a-form-model-item>
					</a-col>
					<a-col :span="12">
						<a-form-model-item label="方法参数" label-position="top" prop="methodParams">
							<a-input v-model="formData.methodParams" type="textarea" placeholder="方法执行传参(字符串)"></a-input>
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
import request from '@/config/request';
import utils from '@/common/utils';

export default {
	data() {
		return {
			show: false,
			formData: {
				taskId: '',
				beanName: '',
				methodName: '',
				methodParams: '',
				cronExpression: '',
				remark: '',
				status: 1
			},
			formRule: {
				beanName: [{ required: true, message: '请输入Bean名称' }],
				methodName: [{ required: true, message: '请输入方法名称' }],
				cronExpression: [{ required: true, message: '请输入Cron表达式' }],
				remark: [{ required: true, message: '请您写个备注好吗?' }]
			}
		};
	},
	methods: {
		_show(form) {
			this.show = true;
			this.$nextTick(() => {
				this.$refs.form.resetFields();
				this.formData.taskId = '';
				if (form) {
					this.formData = JSON.parse(JSON.stringify(form));
				}
			});
		},
		_close() {
			this.show = false;
		},
		doSubmit() {
			this.$refs.form.validate(valid => {
				if (valid) {
					// console.log(this.formData);
					utils.loading();
					request.post('/platform/task/save', this.formData).then(ret => {
						if (ret.code == 200) {
							utils.success('操作成功！').then(() => {
								this.$refs.form.resetFields();
								this.show = false;
								this.$emit('success');
							});
						}
					});
				}
			});
		}
	}
};
</script>
