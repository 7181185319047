<template>
	<div id="ap">
		<a-layout>
			<div class="content-header">
				<a-affix :offset-top="0" style="width: 100%;">
					<a-row type="flex" justify="space-between">
						<a-col span="8">
							<common-page-btn @save="save"></common-page-btn>
						</a-col>
						<a-col span="16">
							<a-row type="flex" align="middle" justify="end" :gutter="3">
								<a-col span="6">
									<a-input v-model="param.keyword" allowClear search @pressEnter="doRefresh"
										@on-clear="doRefresh" placeholder="关键词查询"></a-input>
								</a-col>
							</a-row>
						</a-col>
					</a-row>
				</a-affix>
			</div>
		</a-layout>

		<a-layout>
			<a-layout-content>
				<a-table :scroll="{ x: '100%' }" :pagination="false" rowKey="taskId" :style="{ background: '#FFF' }"
					size="small" border :columns="keys" ref="list" :loading="loading" :data-source="data.records">
					<!--状态-->
					<template slot-scope="row" slot="status">
						<template v-if="row.status == 1">
							<a-tooltip title="运行中"><a-icon type="check-circle" style="color:#090" /></a-tooltip>
						</template>
						<template v-else>
							<a-tooltip title="已暂停"><a-icon type="stop" /></a-tooltip>
						</template>
					</template>
					<!--操作-->
					<template slot-scope="row" slot="action">
						<a-tooltip title="暂停" v-has="'task:stop'" v-if="row.status == 1"><a-button shape="circle"
								type="link" icon="pause-circle" @click="doAction('stop', row)"
								size="small"></a-button></a-tooltip>
						<a-tooltip title="启动" v-has="'task:start'" v-if="row.status == 0"><a-button shape="circle"
								type="link" icon="play-circle" @click="doAction('start', row)"
								size="small"></a-button></a-tooltip>
						<a-tooltip title="编辑" v-has="'task:save'"><a-button shape="circle" type="link" icon="edit"
								@click="save(row)" size="small"></a-button></a-tooltip>
						<a-tooltip title="删除" v-has="'task:del'"><a-button shape="circle" type="link" icon="delete"
								@click="del(row.taskId)"></a-button></a-tooltip>
					</template>
				</a-table>
			</a-layout-content>
		</a-layout>
		<a-row>
			<a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
				<a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext"
					@showSizeChange="setLimit" :current="data.current" />
			</a-col>
		</a-row>
		<a-back-top />

		<task-form ref="taskForm" @success="getList"></task-form>
	</div>
</template>

<script>
import taskForm from '@/views/system/task/taskForm';
export default {
	components: { taskForm },
	data() {
		return {
			loading: false,
			data: {},
			groupList: [],
			param: {
				page: 1,
				limit: 20,
				keyword: '',
				type: ''
			},
			keys: [
				{ title: '编号', dataIndex: 'taskId', width: 100, align: 'center' },
				{ title: '说明', dataIndex: 'remark', align: 'left', ellipsis: true },
				{ title: 'BEAN', dataIndex: 'beanName', width: 180, align: 'left', ellipsis: true },
				{ title: '执行方法', dataIndex: 'methodName', width: 180, align: 'left', ellipsis: true },
				// { title: '携带参数', dataIndex: 'methodParams', align: 'left', ellipsis:true, tooltip: true },
				{ title: '表达式', dataIndex: 'cronExpression', minWidth: 220, align: 'left', ellipsis: true },
				{ title: '创建时间', dataIndex: 'createTime', align: 'center', width: 180, tooltip: true, ellipsis: true },
				{ title: '状态', scopedSlots: { customRender: 'status' }, align: 'center', width: 120, tooltip: true, ellipsis: true },
				{ title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 100 }
			]
		};
	},
	created() {
		this.getList();
	},
	methods: {
		//添加修改页面
		save(row) {
			this.$refs.taskForm._show(row ? row : null);
		},
		//删除
		del(id) {
			this.utils.confirm('删除后无法恢复，是否确认?').then(() => {
				this.http.delete('/platform/task/del/' + id).then(ret => {
					if (ret.code == 200) {
						this.utils.success('操作成功！').then(() => {
							this.getList();
						});
					}
				});
			});
		},
		doRefresh() {
			this.param.page = 1;
			this.getList();
		},
		// 获取数据
		getList() {
			this.utils.loading();
			this.loading = true;
			this.http
				.get('/platform/task/list', this.param)
				.then(ret => {
					this.data = ret.data;
					this.loading = false;
					// console.log(ret);
				})
				.catch(err => {
					this.loading = false;
				});
		},
		// 启动&停止
		doAction(type, task) {
			let uri = type == 'stop' ? '/platform/task/stop/' + task.taskId : '/platform/task/start/' + task.taskId;
			this.http.put(uri, '').then(ret => {
				this.getList();
			});
		},
		//设置每页的显示条数
		setLimit(current, limit) {
			this.param.page = 1;
			this.param.limit = limit;
			this.getList();
		},
		//页码切换
		getNext(page) {
			this.param.page = page;
			this.getList();
		}
	}
};
</script>

<style scoped></style>
